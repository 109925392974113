import { routeToUrl } from "@app-routes";
import { LoginLayout } from "@main-zone/components/login-layout/login-layout";
import { LoginForm } from "@main-zone/forms/login-form/login-form";
import { Button } from "@uxf/ui/button";
import { TextLink } from "@uxf/ui/text-link";
import Router from "next/router";

export function LoginPage() {
    return (
        <LoginLayout pageTitle="Přihlášení" title="Přihlášení">
            <div className="space-y-6">
                <LoginForm />
                <p className="text-center">
                    Pokud jsi členem oddílu OOB TJ Turnov a na e-mail ti nepřišla pozvánka, nebo za oddíl hostuješ,
                    napiš na <TextLink href="mailTo:admin@vittoris.tur.cz">admin@vittoris.tur.cz</TextLink> (nebo přímo
                    Vittonovi) o její zaslání.
                </p>
                <Button
                    isFullWidth
                    variant="outlined"
                    onClick={() => {
                        Router.push(routeToUrl("main-zone/guest-registration"));
                    }}
                >
                    Registrovat se jako host
                </Button>
            </div>
        </LoginLayout>
    );
}
